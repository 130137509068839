import { FC } from 'react'
import './Notifications.scss'
import { Surgery } from 'common/types'
import { Typography, Divider } from '@mui/material'
import dayjs from 'lib/dayjs'

const Notifications: FC<{ surgery: Surgery }> = ({ surgery }) => {
  const notifiedReps = surgery?.salesRepsNotified[0]

  return (
    <div className="notification-container">
      <Typography variant="h2" className="notification-header">
        Notifications
      </Typography>
      <Divider className="divider" />
      {notifiedReps?.salesReps?.length ? (
        notifiedReps.salesReps.map(
          ({
            name,
            email,
            phoneNumber,
            sendEmail,
            sendSms,
            emailSent,
            smsSent,
            emailSentAt,
            smsSentAt,
          }) => (
            <div key={email} className="notification">
              <Typography variant="h3" className="notification-name">
                {name || 'No Name Provided'}
              </Typography>
              <Typography variant="body2" className="notification-email">
                Email: {email}
              </Typography>
              <Typography variant="body2" className="notification-phone">
                Phone: {phoneNumber || 'N/A'}
              </Typography>
              {sendEmail && (
                <Typography
                  variant="body2"
                  className={emailSent ? 'status-success' : 'status-failure'}
                >
                  Email Sent: {emailSent ? '✅' : '❌'}
                  {emailSentAt && (
                    <span className="status-timestamp">
                      {' '}
                      (at {dayjs(emailSentAt).format('HH:mm, MMM DD')})
                    </span>
                  )}
                </Typography>
              )}
              {sendSms && (
                <Typography
                  variant="body2"
                  className={smsSent ? 'status-success' : 'status-failure'}
                >
                  SMS Sent: {smsSent ? '✅' : '❌'}
                  {smsSentAt && (
                    <span className="status-timestamp">
                      {' '}
                      (at {dayjs(smsSentAt).format('HH:mm, MMM DD')})
                    </span>
                  )}
                </Typography>
              )}
            </div>
          )
        )
      ) : (
        <Typography variant="body1" className="no-notifications">
          No notifications found.
        </Typography>
      )}
    </div>
  )
}

export default Notifications
