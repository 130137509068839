import { FC } from 'react'

// Components
import {
  Typography,
  Checkbox,
  CircularProgress,
  IconButton,
} from '@mui/material'
import { Delete } from '@mui/icons-material'

// Types
import { RepItemProps } from './RepItem.types'

// Style
import './RepItem.scss'

// Other
import classNames from 'classnames'

export const RepItem: FC<RepItemProps> = (props) => {
  const {
    rep,
    notifyEmail,
    notifySms,
    isLoading,
    handleSendSurgeryToRep,
    handleDelete,
  } = props

  // Handlers for checkbox changes
  const handleCheckboxChange = (type: 'EMAIL' | 'SMS', checked: boolean) => {
    handleSendSurgeryToRep(rep.email, checked, type)
  }

  return (
    <div
      key={rep?.email}
      className={classNames('pref-card-rep-item-container')}
    >
      <div className="details-container">
        <div>
          <Typography variant="h4">{rep.name}</Typography>
          <Typography variant="subtitle2">{rep.email}</Typography>
        </div>
      </div>
      <div className="actions">
        {isLoading ? (
          <CircularProgress size={23} />
        ) : (
          <>
            <div className="notification-checkbox">
              <Checkbox
                checked={notifyEmail}
                onChange={(e) =>
                  handleCheckboxChange('EMAIL', e.target.checked)
                }
                color="primary"
              />
              <Typography variant="body2">Email</Typography>
            </div>
            <div className="notification-checkbox">
              <Checkbox
                checked={notifySms}
                onChange={(e) => handleCheckboxChange('SMS', e.target.checked)}
                color="primary"
              />
              <Typography variant="body2">SMS</Typography>
            </div>
          </>
        )}
        <IconButton onClick={() => handleDelete(rep)}>
          <Delete color="primary" />
        </IconButton>
      </div>
    </div>
  )
}
