import { useUser } from 'app/User'
import {
  useGetSubTrayItemById,
  useGetTrayItemByBarcode,
} from 'lib/apollo/hooks'
import { extractSubTrayJSONData } from 'lib/utils/extractSubTrayJSONData'
import { extractTrayJSONData } from 'lib/utils/extractTrayJSONData'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const useSubTrayDetails = () => {
  const { trayId, subTrayItemId } = useParams()
  const { loading, error, subTrayItem, getSubTrayItem } =
    useGetSubTrayItemById()
  const {
    loading: loadingParentTray,
    error: parentTrayError,
    trayItem,
    getTrayItem,
  } = useGetTrayItemByBarcode()
  const { user } = useUser()
  const navigate = useNavigate()

  const subTrayDetails = extractSubTrayJSONData(subTrayItem)
  const parentTrayDetails = extractTrayJSONData(trayItem)
  const isRep = user?.roles?.includes('MGAT_REP')
  const isSPD = user?.roles?.includes('MGAT_SPD')
  const shouldEditButtonBeVisible =
    (isRep && parentTrayDetails?.status === 'rejected') ||
    (isSPD && parentTrayDetails?.trayCategory === 'facility')

  useEffect(() => {
    if (!subTrayItemId) {
      navigate('..')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTrayItemId])

  useEffect(() => {
    if (!loading) {
      getSubTrayItem({
        variables: { subTrayItemId: Number(subTrayItemId) as number },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTrayItemId])

  useEffect(() => {
    if (!loadingParentTray) {
      getTrayItem({ variables: { barcode: trayId as string } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trayId])

  const handleEditTrayClick = () => {
    if (subTrayItemId) {
      navigate(`../${trayId}/${subTrayItemId}/edit`)
    }
  }

  const handleBackClick = () => {
    if (trayId) {
      navigate(`/tray-management/${trayId}`)
    }
  }

  return {
    subTrayItemId,
    subTrayItem,
    loading,
    error,
    subTrayDetails,
    handleEditTrayClick,
    handleBackClick,
    loadingParentTray,
    parentTrayError,
    shouldEditButtonBeVisible,
  }
}
