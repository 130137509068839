// MUI
import { Button, Checkbox, FormControl, Typography } from '@mui/material'

// Components
import { DateInput } from 'components/mui'
import LoadingButton from 'components/molecules/LoadingButton/LoadingButton'
import Counter from 'components/Counter/Counter'
import Select from 'components/molecules/Select/Select'
import PaginatedSearch from 'components/organisms/PaginatedSearch/PaginatedSearch'
import TextInput from 'components/molecules/TextInput/TextInput'

// Logic
import useManualAddFormLogic from './ManualAddForm.logic'

// Types
import { ManualAddFormProps } from './ManualAddForm.types'

// Style
import './ManualAddForm.scss'
import { BarcodeIcon } from 'assets/icons/BarcodeIcon'
import { BatchSetCountModal } from 'components/BatchSetCountModal'
import { BetterIdAsset } from 'common/types'
import ToastProvider from 'common/ToastProvider'

const ManualAddForm = (props: ManualAddFormProps) => {
  const {
    isDisabled,
    formErrors,
    assetFormData,
    manualAssetInputFormErrors,
    handleChangeFormData,
    handleChangeFormErrorField,
    onSearchWithTypeChange,
  } = props

  const {
    codeValue,
    searchType,
    hasQuantity,
    isDrawerOpen,
    isRefetching,
    selectedAsset,
    repBidCompanyId,
    isSearchWithUDI,
    selectedCompany,
    isLoadingOptions,
    openQuantityModal,
    isLoadingUndocumentedList,
    setSearchType,
    setCodeValue,
    customOptions,
    setIsDrawerOpen,
    handleFormSubmit,
    customPlaceholder,
    handleOpenScanner,
    handleCloseDrawer,
    handleSearchSelect,
    handleInfiniteScroll,
    setOpenQuantityModal,
    handleSetUsedQuantity,
  } = useManualAddFormLogic(props)

  return (
    <form onSubmit={handleFormSubmit} className="non-udi-form-container">
      <ToastProvider
        style={{
          marginTop: '100px',
        }}
      >
        <Typography
          variant="h3"
          my={3}
          fontSize="large"
          fontWeight="400"
          color="GrayText"
        >
          {`Manual lookup using ${
            isSearchWithUDI ? 'UDI' : 'Device Description/Catalog Number'
          }`}
        </Typography>
        <FormControl fullWidth className="main-container">
          <Select
            variant="filled"
            label="Search with"
            selectedValue={searchType}
            onSelect={(option) => {
              onSearchWithTypeChange?.(option.id === 'UDI')
              setSearchType(option.id as typeof searchType)
            }}
            options={
              [
                { id: 'UDI', name: 'UDI' },
                {
                  id: 'Device Description/Catalog Number',
                  name: 'Device Description/Catalog Number',
                },
              ] as any
            }
          />
          <div className="main-text-fields">
            {isSearchWithUDI ? (
              <div className="mt-1">
                <TextInput
                  id="udi"
                  label="UDI*"
                  name="udi"
                  placeholder="Enter UDI"
                  variant="standard"
                  value={assetFormData.udi}
                  handleChangeFormData={handleChangeFormData}
                  onClick={() => setIsDrawerOpen({ isOpen: true, type: 'UDI' })}
                  errorMessage={formErrors?.udi}
                />
              </div>
            ) : (
              <div className="search-inputs mt-1">
                <TextInput
                  id="companyName"
                  label="Company Name*"
                  name="companyName"
                  variant="standard"
                  handleChangeFormData={handleChangeFormData}
                  onClick={() =>
                    setIsDrawerOpen({ isOpen: true, type: 'company' })
                  }
                  value={assetFormData.companyName}
                  errorMessage={formErrors?.companyName}
                />

                <TextInput
                  id="companyName"
                  label="Device Description*"
                  name="deviceDescription"
                  variant="standard"
                  handleChangeFormData={handleChangeFormData}
                  value={assetFormData.deviceDescription}
                  onClick={() => {
                    if (!selectedCompany?.id) return
                    setIsDrawerOpen({ isOpen: true, type: 'description' })
                  }}
                  disabled={
                    (!repBidCompanyId && !(selectedCompany?.id as number)) ||
                    !assetFormData.bidCompanyId
                  }
                  errorMessage={formErrors?.deviceDescription}
                />

                <div className="field">
                  <TextInput
                    id="catalogNumber"
                    label="Catalog number"
                    name="catalogNumber"
                    placeholder="Enter catalog number"
                    variant="standard"
                    handleChangeFormData={handleChangeFormData}
                    sx={{ my: 0 }}
                    onClick={() => {
                      if (!selectedCompany?.id) return
                      setIsDrawerOpen({ isOpen: true, type: 'catalogNumber' })
                    }}
                    value={
                      assetFormData.catalogNumber ||
                      assetFormData.versionModelNumber
                    }
                    disabled={
                      !(selectedCompany?.id as number) ||
                      !assetFormData.bidCompanyId
                    }
                  />
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOpenScanner('catalog')}
                    className="barcode-button"
                    disabled={
                      !(selectedCompany?.id as number) ||
                      !assetFormData.bidCompanyId
                    }
                  >
                    <BarcodeIcon
                      active
                      color={
                        !(selectedCompany?.id as number) ||
                        !assetFormData.bidCompanyId
                          ? 'var(--grayscale)'
                          : 'var(--primary)'
                      }
                    />
                  </Button>
                </div>
              </div>
            )}
          </div>

          <PaginatedSearch
            isOpen={isDrawerOpen.isOpen}
            onClose={handleCloseDrawer}
            value={codeValue}
            isPagination
            isFetching={isRefetching}
            handleInfiniteScroll={handleInfiniteScroll}
            name={isDrawerOpen.type}
            placeholder={customPlaceholder()}
            searchValue={codeValue}
            onChange={(value) => setCodeValue(value)}
            isLoading={isLoadingOptions}
            options={customOptions()}
            onSelect={(option) => {
              handleSearchSelect(option)
              setCodeValue('')
              setIsDrawerOpen({ isOpen: false })
            }}
          />

          <div className="optional-fields-container">
            <DateInput
              format="MM/DD/YYYY"
              readOnly
              openTo="month"
              shrink={Boolean(assetFormData.expirationDate)}
              handleChangeFormData={handleChangeFormData}
              variant="standard"
              id="expirationDate"
              label="Expiration Date (optional)"
              name="expirationDate"
              error={manualAssetInputFormErrors.expirationDate}
              value={assetFormData.expirationDate}
              handleChangeFormErrorField={handleChangeFormErrorField}
            />

            <div className="has-serial-lot-condition">
              <div className="checkbox-container">
                <Checkbox
                  checked={assetFormData.hasLotSerial}
                  className="checkbox"
                  name="hasLotSerial"
                  onChange={(_, checked) => {
                    handleChangeFormData({
                      target: {
                        name: 'hasLotSerial',
                        value: checked,
                      },
                    })
                  }}
                />
                <Typography>
                  Lot/Serial not available for this product.
                </Typography>
              </div>
            </div>

            {!assetFormData.hasLotSerial && (
              <>
                <div className="field">
                  <TextInput
                    handleChangeFormData={handleChangeFormData}
                    id="lotBatch"
                    label="Lot / Batch Number*"
                    name="lotBatch"
                    value={assetFormData.lotBatch}
                    sx={{ mt: 0 }}
                    onClick={() => {}}
                  />
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOpenScanner('lot')}
                    className="barcode-button"
                  >
                    <BarcodeIcon active color="var(--primary)" />
                  </Button>
                </div>
                <div className="field">
                  <TextInput
                    handleChangeFormData={handleChangeFormData}
                    id="serialNumber"
                    label="Stock / Serial Number*"
                    name="serialNumber"
                    value={assetFormData.serialNumber}
                    sx={{ mt: 0 }}
                  />
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOpenScanner('serial')}
                    className="barcode-button"
                  >
                    <BarcodeIcon active color="var(--primary)" />
                  </Button>
                </div>
                <Typography color="red" fontSize="12px" my={-1} ml={0.5}>
                  {formErrors?.lotSerial}
                </Typography>
              </>
            )}
          </div>

          {hasQuantity && (
            <div className="counter-container">
              <Counter
                name="count"
                label="Quantity"
                initialValue={assetFormData.quantity}
                onChange={(value) =>
                  handleChangeFormData({
                    target: {
                      name: 'quantity',
                      value: value,
                    },
                  })
                }
              />
            </div>
          )}

          <div className="save-button">
            <LoadingButton
              type="submit"
              loading={isLoadingUndocumentedList}
              disabled={isDisabled}
            >
              Save
            </LoadingButton>
          </div>
        </FormControl>

        <BatchSetCountModal
          isOpen={openQuantityModal}
          scan={selectedAsset as BetterIdAsset}
          onClose={() => setOpenQuantityModal(false)}
          onSave={handleSetUsedQuantity}
          onCountChange={(count) =>
            handleChangeFormData({
              target: {
                name: 'count',
                value: count,
              },
            })
          }
        />
      </ToastProvider>
    </form>
  )
}
export default ManualAddForm
