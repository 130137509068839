import { FC } from 'react'
import TextInput from 'components/molecules/TextInput/TextInput'
import { CustomModal } from 'components/molecules/Modal/Modal'
import {
  NewSalesRepProps,
  ISalesRepModal,
  Specialties,
} from '../CardView.types'
import { Controller, useForm } from 'react-hook-form'
import { formatUSPhoneNumber } from 'common/utils'

const AddSalesRep: FC<ISalesRepModal> = (props) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<NewSalesRepProps>({
    mode: 'onChange',
  })
  const { isOpen, type, isLoading, company, onAdd, onClose } = props
  const email = watch('email')

  const onSubmit = (data: NewSalesRepProps) => {
    const { email, firstName, lastName, phoneNumber } = data
    onAdd?.({
      id: '',
      email,
      firstName,
      lastName,
      name: `${firstName} ${lastName}`,
      phoneNumber,
      companyId: company?.id as number,
      companyName: company?.name,
      sendEmail: false,
      sendSms: false,
      isSetup: true,
      isSpecialtyRep: type !== 'rep',
      specialty: type as Specialties,
    })
  }

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  return (
    <CustomModal
      isLoadingState={isLoading as boolean}
      open={isOpen}
      handleOnSubmit={handleOnSubmit}
      icon={<></>}
      header={type === 'rep' ? 'Add New Sales Rep' : `Set Up ${type} Rep`}
      secondaryButtonAction={onClose}
    >
      <form>
        <Controller
          rules={{
            required: {
              message: 'This field is required',
              value: true,
            },
          }}
          name="firstName"
          control={control}
          render={({ field: { onChange, value, ...field } }) => (
            <TextInput
              {...field}
              value={value}
              handleChangeFormData={onChange}
              variant="outlined"
              placeholder="First Name"
              label="First Name"
              error={!!errors.firstName}
              errorMessage={errors.firstName?.message}
              shrink
            />
          )}
        />
        <Controller
          rules={{
            required: {
              message: 'This field is required',
              value: true,
            },
          }}
          name="lastName"
          control={control}
          render={({ field: { onChange, value, ...field } }) => (
            <TextInput
              {...field}
              value={value}
              handleChangeFormData={onChange}
              variant="outlined"
              placeholder="Last Name"
              label="Last Name"
              error={!!errors.lastName}
              errorMessage={errors.lastName?.message}
              shrink
            />
          )}
        />
        <Controller
          rules={{
            required: {
              message: 'This field is required',
              value: true,
            },
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: 'Please enter a valid email address',
            },
          }}
          name="email"
          control={control}
          render={({ field: { onChange, value, ...field } }) => (
            <TextInput
              {...field}
              value={value}
              handleChangeFormData={onChange}
              variant="outlined"
              label="Email"
              placeholder="Email"
              error={!!errors.email}
              errorMessage={errors.email?.message}
              shrink
            />
          )}
        />

        <Controller
          rules={{
            required: {
              message: 'This field is required',
              value: true,
            },
            validate: (value) => value === email || 'Emails do not match',
          }}
          name="confirmEmail"
          control={control}
          render={({ field: { onChange, value, ...field } }) => (
            <TextInput
              {...field}
              value={value}
              handleChangeFormData={onChange}
              variant="outlined"
              label="Confirm Email"
              placeholder="Confirm Email"
              error={!!errors.confirmEmail}
              errorMessage={errors.confirmEmail?.message}
              shrink
            />
          )}
        />

        <Controller
          rules={{
            required: {
              message: 'This field is required',
              value: true,
            },
            pattern: {
              value: /^\(\d{3}\) \d{3}-\d{4}$/,
              message: 'Mobile number must be in the format (321) 323-2131',
            },
          }}
          name="phoneNumber"
          control={control}
          render={({ field: { onChange, value, ...field } }) => (
            <TextInput
              {...field}
              value={value}
              handleChangeFormData={(e) => {
                const input = e.target.value.replace(/\D/g, '')
                onChange(formatUSPhoneNumber(input))
              }}
              shrink
              id="mobile"
              label="Phone Number"
              placeholder="Phone Number"
              name="mobile"
              inputMode="text"
              variant="outlined"
              error={!!errors.phoneNumber}
              errorMessage={errors.phoneNumber?.message}
            />
          )}
        />
      </form>
    </CustomModal>
  )
}

export default AddSalesRep
