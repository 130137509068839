/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react'

// Services
import {
  useBetterIdLookup,
  useLazyCompanySearchQuery,
  usePaginatedCompanySearch,
  useSearchBetterIdWithMetaData,
} from 'lib/apollo/hooks'

// Utils
import { getAssetTypeCaps } from 'lib/utils/getAssetType'

// Types
import {
  ManualAddFormProps,
  ManualDrawerProps,
  ScannedField,
} from './ManualAddForm.types'
import { BetterIdAsset, ManualInputFormData, Option } from 'common/types'
import { SearchType } from './ManualAddForm.types'

// Other
import { debounce, set } from 'lodash'
import { useUndocumentedAssetsContext } from 'lib/context/UndocumentedAssetsContext'
import { useCortexDecoderContext } from 'lib/batchScanner'
import { useAssetType } from 'lib/context/AssetTypeContext/AssetTypeContext'
import { useUser } from 'app/User'

const useManualAddFormLogic = ({
  assetFormData,
  handleSubmit,
  setIsTrayScannerOpen,
  registerTrayScanHandler,
  setManualAssetInputFormData,
}: ManualAddFormProps) => {
  const { setCurrentScan, currentScan, resetScan } = useCortexDecoderContext()

  const [searchType, setSearchType] = useState<
    'UDI' | 'Device Description/Catalog Number'
  >('Device Description/Catalog Number')
  const isSearchWithUDI = searchType === 'UDI'
  const isSearchWithOther = searchType === 'Device Description/Catalog Number'
  const [codeValue, setCodeValue] = useState<string>()
  const [selectedCompany, setSelectedCompany] = useState<Option>()
  const [selectedAssetOption, setSelectedAssetOption] = useState<Option>()
  const [udi, setUDI] = useState<string>()
  const [isDrawerOpen, setIsDrawerOpen] = useState<ManualDrawerProps>({
    isOpen: false,
  })
  const [scannedField, setScannedField] = useState<ScannedField>()
  const [hasQuantity, setHasQuantity] = useState(false)
  const [isRefetching, setIsRefetching] = useState(false)
  const [openQuantityModal, setOpenQuantityModal] = useState(false)
  const [selectedAsset, setSelectedAsset] = useState<BetterIdAsset>()
  const [event, setEvent] = useState<any>(null)
  const { isLoadingUndocumentedList } = useUndocumentedAssetsContext()
  const { assetType } = useAssetType()
  const [filteredRepCompanies, setFilteredRepCompanies] = useState<Option[]>([])
  const [repCompanies, setRepCompanies] = useState<Option[]>([])

  const canSave =
    !!assetFormData.bidAssetId &&
    !!assetFormData.deviceId &&
    (!assetFormData.hasLotSerial
      ? !!assetFormData.lotBatch || !!assetFormData.serialNumber
      : true)

  const customPlaceholder = () => {
    if (!isDrawerOpen.isOpen) return

    const placeholders = {
      company: 'Search for a company',
      description: 'Search with product description',
      catalogNumber: 'Search with catalog number',
      UDI: 'Search with UDI',
      default: '',
    }

    return placeholders[isDrawerOpen.type as SearchType]
  }

  const { isRep, user } = useUser()
  const repBidCompanyId = isRep ? user?.bidCompanyId || null : null
  const repCompanyIds = isRep
    ? [
        user?.bidCompanyId as number,
        ...(user?.parentCompanyId ? [user.parentCompanyId] : []),
        ...((user?.siblingCompaniesIds as number[]) ?? []),
        ...((user?.subsidiariesCompaniesIds as number[]) ?? []),
      ]
    : []

  useEffect(() => {
    if (repBidCompanyId) {
      getRepCompanies({
        variables: {
          companyIds: repCompanyIds,
        },
      }).then((res) => {
        if (res?.data?.companySearch) {
          setRepCompanies(
            res.data.companySearch.map((company) => ({
              id: company.id ?? 0,
              name: company.name,
            }))
          )
          setFilteredRepCompanies(
            res.data.companySearch.map((company) => ({
              id: company.id ?? 0,
              name: company.name,
            }))
          )
        }
      })
    }
  }, [])

  const [getRepCompanies, { loading: isRepCompaniesLoading }] =
    useLazyCompanySearchQuery()

  // Services
  const [
    getCompanies,
    {
      loading: isLoadingCompanies,
      data: rawCompaniesData,
      fetchMore: fetchMoreCompanies,
    },
  ] = usePaginatedCompanySearch()

  const [
    search,
    {
      loading: isLoadingSearch,
      data: rawSearchData,
      fetchMore: fetchMoreProducts,
    },
  ] = useSearchBetterIdWithMetaData()

  const [searchUDI, { loading: isLoadingSearchUDI, data: rawSearchUDIData }] =
    useBetterIdLookup()

  const companies = (
    repBidCompanyId
      ? filteredRepCompanies
      : rawCompaniesData?.companySearchPaginated.companies.map((company) => ({
          id: company.id,
          name: company.name,
        })) || []
  ) as Option[]

  const searchData =
    rawSearchData?.betterIdLookupByModelOrDD.products.map((item) => ({
      id: item.id,
      name: `${item.deviceDescription} (CN: ${
        item.catalogNumber || item.versionModelNumber
      })` as string,
      isMultipack: item.deviceCount > 1,
    })) || []

  const searchUDIOptions = repBidCompanyId
    ? (rawSearchUDIData?.betterIdLookup.assets
        .filter((item) => repCompanyIds.includes(item.companyId as number))
        .map((item) => ({
          id: item.id,
          name: `${item.deviceDescription} (CN: ${
            item.catalogNumber || item.versionModelNumber
          })`,
        })) as Option[]) || []
    : (rawSearchUDIData?.betterIdLookup.assets.map((item) => ({
        id: item.id,
        name: `${item.deviceDescription} (CN: ${
          item.catalogNumber || item.versionModelNumber
        })`,
      })) as Option[]) || []

  const isLoadingOptions =
    isLoadingCompanies ||
    isLoadingSearch ||
    isLoadingSearchUDI ||
    isRepCompaniesLoading

  const CUSTOM_OPTION_TYPES: { [key in SearchType]: Option[] } = {
    company: companies,
    description: searchData,
    catalogNumber: searchData,
    UDI: searchUDIOptions,
    default: [],
  }

  const customOptions = (): Option[] => {
    return CUSTOM_OPTION_TYPES[isDrawerOpen.type as SearchType]
  }

  const handleCloseDrawer = () => {
    setIsDrawerOpen({ isOpen: false })
    setCodeValue(undefined)
    setFilteredRepCompanies(repCompanies)
  }

  const debouncedSetSearchValue = useCallback(
    debounce((value: string) => {
      switch (isDrawerOpen.type) {
        case 'company':
          if (isRep) {
            setFilteredRepCompanies(
              repCompanies.filter((company) =>
                company.name.toLowerCase().includes(value.toLowerCase())
              )
            )
          } else
            getCompanies({
              variables: {
                data: {
                  name: value,
                  limit: 15,
                },
              },
            })
          break
        case 'description':
          search({
            variables: {
              data: {
                companyId:
                  (selectedCompany?.id as number) || assetFormData.bidCompanyId,
                deviceDescription: value,
                limit: 15,
              },
            },
          })
          break
        case 'catalogNumber':
          search({
            variables: {
              data: {
                companyId:
                  (selectedCompany?.id as number) || assetFormData.bidCompanyId,
                versionModelNumber: value,
                limit: 15,
              },
            },
          })
          break
        case 'UDI':
          searchUDI({
            variables: {
              lookupValue: value,
            },
          })
          setUDI(value)
          break
        default:
          break
      }
    }, 300),
    [isDrawerOpen.type]
  )

  const updateFormData = (update: Partial<ManualInputFormData>) => {
    setManualAssetInputFormData((prev) => ({
      ...prev,
      ...update,
    }))
  }

  const handleSearchSelect = (option: Option) => {
    switch (isDrawerOpen.type) {
      case 'company':
        setSelectedCompany(option)
        updateFormData({
          companyName: option?.name as string,
          bidCompanyId: option?.id as number,
          deviceDescription: undefined,
          catalogNumber: undefined,
          versionModelNumber: undefined,
        })
        break
      case 'description':
        setSelectedAssetOption(option)
        updateFormData({
          deviceDescription: option.name,
        })
        break
      case 'catalogNumber':
        setSelectedAssetOption(option)
        updateFormData({
          catalogNumber: option.name,
        })
        break
      case 'UDI':
        setSelectedAssetOption(option)
        updateFormData({
          deviceDescription: option.name,
        })
        break
      default:
        break
    }
    setIsDrawerOpen({ isOpen: false })
  }

  const handleInfiniteScroll = useCallback(() => {
    if (isDrawerOpen?.type === 'company') {
      const companies = rawCompaniesData?.companySearchPaginated?.companies
      if (!companies) return

      const totalCount = rawCompaniesData.companySearchPaginated.totalCount

      if (companies.length >= totalCount) return

      setIsRefetching(true)

      fetchMoreCompanies({
        variables: {
          data: {
            name: codeValue,
            limit: 15,
            skip: companies.length,
          },
        },
        updateQuery(prev, { fetchMoreResult }) {
          if (!fetchMoreResult) {
            setIsRefetching(false)
            return prev
          }

          const newItems =
            fetchMoreResult.companySearchPaginated.companies.filter(
              (newItem) =>
                !prev.companySearchPaginated.companies.some(
                  (prevItem) => prevItem.id === newItem.id
                )
            )

          setIsRefetching(false)

          return {
            companySearchPaginated: {
              ...prev.companySearchPaginated,
              companies: [
                ...prev.companySearchPaginated.companies,
                ...newItems,
              ],
              totalCount,
            },
          }
        },
      }).catch(() => setIsRefetching(false)) // Ensure state reset on error​
    }
    if (
      isDrawerOpen.type === 'description' ||
      isDrawerOpen.type === 'catalogNumber'
    ) {
      if (!rawSearchData) return

      const totalCount = rawSearchData?.betterIdLookupByModelOrDD?.totalCount
      const products = rawSearchData.betterIdLookupByModelOrDD?.products || []
      const productsLength = products.length

      if (productsLength >= totalCount) return

      setIsRefetching(true)

      fetchMoreProducts({
        variables: {
          data: {
            companyId:
              (selectedCompany?.id as number) || assetFormData.bidCompanyId,
            deviceDescription: codeValue,
            limit: 20,
            cursor:
              rawSearchData.betterIdLookupByModelOrDD?.products[
                rawSearchData.betterIdLookupByModelOrDD?.products.length - 1
              ].id,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const newItems =
            fetchMoreResult.betterIdLookupByModelOrDD.products.filter(
              (newItem) =>
                !prev.betterIdLookupByModelOrDD.products.some(
                  (prevItem) => prevItem.id === newItem.id
                )
            )

          setIsRefetching(false)

          return {
            betterIdLookupByModelOrDD: {
              products: [
                ...prev.betterIdLookupByModelOrDD.products,
                ...newItems,
              ],
              totalCount: prev.betterIdLookupByModelOrDD.totalCount,
            },
          }
        },
      }).catch(() => setIsRefetching(false))
    }
  }, [
    codeValue,
    isDrawerOpen,
    rawSearchData,
    rawCompaniesData,
    fetchMoreProducts,
    fetchMoreCompanies,
  ])

  const handleOpenScanner = (field: ScannedField) => {
    setScannedField(field)
    setIsTrayScannerOpen && setIsTrayScannerOpen(true)
  }

  const handleSetUsedQuantity = () => {
    setOpenQuantityModal(false)
    handleSubmit(event)
  }

  const handleFormSubmit = (e: any) => {
    e.preventDefault()
    if (
      (selectedAsset?.deviceCount as number) > 1 &&
      assetType === 'CONSUMABLE PRODUCT'
    ) {
      setEvent(e)
      setOpenQuantityModal(true)
    } else {
      handleSubmit(e)
    }
  }

  useEffect(() => {
    if (codeValue) {
      debouncedSetSearchValue(codeValue)
    }
    return () => {
      debouncedSetSearchValue.cancel()
    }
  }, [codeValue, debouncedSetSearchValue])

  useEffect(() => {
    const setFormData = (asset?: BetterIdAsset) => {
      if (!asset) return
      setSelectedAsset(asset)
      setManualAssetInputFormData((prev) => ({
        ...prev,
        catalogNumber: asset.catalogNumber || asset.versionModelNumber,
        assetType: getAssetTypeCaps(assetType),
        deviceCount: asset.deviceCount,
        deviceDescription: asset.deviceDescription,
        versionModelNumber: asset.versionModelNumber,
        bidCompanyId: asset.companyId,
        bidAssetId: asset.id,
        chargeable: asset.chargeable,
        cost: asset.cost,
        deviceId: asset.deviceId,
        fromAssetInstanceId: asset.fromAssetInstanceId,
        gmdnPTDefinition: asset.gmdnPTDefinition,
        id: asset.id,
        issuingAgency: asset.issuingAgency,
        pkgQuantity: asset.pkgQuantity,
        secondaryDeviceId: asset.secondaryDeviceId,
        secondaryDeviceIdPkgQuantity: asset.secondaryDeviceIdPkgQuantity,
        secondaryDeviceIdPkgType: asset.secondaryDeviceIdPkgType,
        secondaryDeviceIdType: asset.secondaryDeviceIdType,
        sizeString: asset.sizeString,
        sizeText: asset.sizeText,
        sourceId: asset.sourceId,
        udi,
        isManualAddition: true,
        quantity: assetFormData.quantity as number,
        ...(isSearchWithUDI && {
          companyName: asset.company.name,
          companyId: asset.company.id,
          serialNumber:
            rawSearchUDIData?.betterIdLookup.productionIdentifier
              .serialNumber || undefined,
          lotBatch:
            rawSearchUDIData?.betterIdLookup.productionIdentifier.lotBatch ||
            undefined,
          expirationDate:
            rawSearchUDIData?.betterIdLookup.productionIdentifier
              .expirationDate || undefined,
          manufacturingDate:
            rawSearchUDIData?.betterIdLookup.productionIdentifier
              .manufacturingDate || undefined,
        }),
      }))
    }

    let asset = {} as BetterIdAsset | undefined

    if (isSearchWithOther) {
      asset = rawSearchData?.betterIdLookupByModelOrDD.products.find(
        (item) => item.id === selectedAssetOption?.id
      )
      setFormData(asset)
    }

    if (isSearchWithUDI) {
      asset = rawSearchUDIData?.betterIdLookup.assets.find(
        (item) => item.id === selectedAssetOption?.id
      )
      setFormData(asset)
    }

    if (assetType === 'IMPLANTABLE BIOLOGIC') {
      setHasQuantity(false)
    } else if ((asset?.deviceCount as number) > 1) {
      setHasQuantity(false)
    } else {
      setHasQuantity(true)
    }
  }, [rawSearchData, rawSearchUDIData, selectedAssetOption, assetType])

  useEffect(() => {
    if (selectedCompany) {
      search({
        variables: {
          data: {
            companyId: selectedCompany.id as number,
            limit: 20,
          },
        },
      })
    }
  }, [selectedCompany])

  useEffect(() => {
    if (!currentScan) return
    setCurrentScan(undefined)

    if (scannedField === 'lot') {
      updateFormData({
        lotBatch: currentScan.barcodeData,
      })
    }
    if (scannedField === 'serial') {
      updateFormData({
        serialNumber: currentScan.barcodeData,
      })
    }
    if (scannedField === 'catalog') {
      setCodeValue(currentScan.barcodeData)
      setIsDrawerOpen({ isOpen: true, type: 'catalogNumber' })
    }

    resetScan()
    setIsTrayScannerOpen(false)
  }, [registerTrayScanHandler, currentScan])

  // Add mock scanner for testing
  useEffect(() => {
    if (window && process.env.REACT_APP_NODE_ENV !== 'production') {
      window.mockScan = (scan: string) => {
        if (scannedField === 'lot') {
          updateFormData({
            lotBatch: scan,
          })
        } else if (scannedField === 'serial') {
          updateFormData({
            serialNumber: scan,
          })
        } else if (scannedField === 'catalog') {
          setCodeValue(scan)
          setIsDrawerOpen({ isOpen: true, type: 'catalogNumber' })
        }
        setCurrentScan(undefined)
      }
    }
  }, [registerTrayScanHandler])

  return {
    canSave,
    codeValue,
    searchType,
    hasQuantity,
    isDrawerOpen,
    isRefetching,
    selectedAsset,
    repBidCompanyId,
    isSearchWithUDI,
    selectedCompany,
    isLoadingOptions,
    openQuantityModal,
    isSearchWithOther,
    isLoadingUndocumentedList,
    setSearchType,
    setCodeValue,
    customOptions,
    setIsDrawerOpen,
    handleFormSubmit,
    customPlaceholder,
    handleOpenScanner,
    handleCloseDrawer,
    handleSearchSelect,
    handleInfiniteScroll,
    setOpenQuantityModal,
    handleSetUsedQuantity,
  }
}

export default useManualAddFormLogic
