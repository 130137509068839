import { Typography } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment/InputAdornment'
import TextField, { TextFieldProps } from '@mui/material/TextField/TextField'
import { ChangeEvent, ClipboardEvent, ReactNode } from 'react'
import './TextInput.scss'
import { useTextInputLogic } from './TextInput.logic'

export type TextInputProps = TextFieldProps & {
  delimiter?: string
  startAdornment?: string | ReactNode
  handleChangeFormData?: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  fullwidth?: boolean
  sx?: Record<string, any>
  small?: boolean
  required?: boolean
  validateEmail?: boolean
  disabled?: boolean
  errorMessage?: string
  shrink?: boolean
  disablePaste?: boolean
}

function TextInput({
  delimiter,
  value = '',
  handleChangeFormData,
  inputMode = 'text',
  startAdornment,
  variant,
  inputProps,
  InputProps,
  placeholder,
  fullwidth = true,
  small,
  required = false,
  sx = { mb: required ? 0 : '0.5em', mt: '0.5em', marginX: 0 },
  validateEmail = false,
  label,
  disabled = false,
  className,
  errorMessage,
  shrink,
  disablePaste = false,
  ...props
}: TextInputProps) {
  const valueToPass = props.type === 'number' ? Number(value) : value

  const { caption } = useTextInputLogic({
    value: valueToPass,
    required,
    validateEmail,
  })

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    if (disablePaste) {
      e.preventDefault()
    }
  }

  return (
    <div className="textinput-container">
      <TextField
        {...props}
        className={`textinput-form ${fullwidth ? 'fullwidth' : ''} ${variant} ${
          className ? className : ''
        }`}
        label={label}
        disabled={disabled}
        size={small ? 'small' : 'medium'}
        onChange={handleChangeFormData}
        placeholder={placeholder}
        value={value}
        variant={variant ?? 'standard'}
        sx={{ ...sx }}
        inputProps={{
          min: 0,
          inputMode: inputMode,
          ...inputProps,
        }}
        InputProps={
          InputProps ?? {
            startAdornment: (delimiter || startAdornment) && (
              <InputAdornment position="start">
                {startAdornment ?? `(${delimiter})`}
              </InputAdornment>
            ),
          }
        }
        InputLabelProps={{
          //TODO @rami-reda this condition should be added to the instance to avoid limitations
          // shrink: value ? true : shrink,
          shrink,
        }}
        onPaste={handlePaste}
      />
      {required && (
        <Typography variant="caption" className="input-caption">
          {caption()}
        </Typography>
      )}
      {errorMessage && (
        <Typography
          fontSize={12}
          color="var(--error)"
          ml={1}
          mt={-0.6}
          mb={0.5}
        >
          {errorMessage}
        </Typography>
      )}
    </div>
  )
}

export default TextInput
