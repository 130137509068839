import { ZodError } from 'zod'

export const getZodError = (error: ZodError) => {
  const errorObject = error.issues.reduce((acc, issue) => {
    const path = issue.path.join('.')
    acc[path] = issue.message
    return acc
  }, {} as Record<string, string>)
  return errorObject
}

export const formatUSPhoneNumber = (value: string) => {
  if (!value) {
    return value
  }

  const phoneNumber = value.replace(/[^\d]/g, '')
  const phoneNumberLength = phoneNumber.length

  if (phoneNumberLength < 4) {
    return phoneNumber
  }

  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`
}

export const formatUSPhoneNumberForSubmission = (phone: string) => {
  const cleaned = phone.replace(/[()\s-]/g, '')

  return `+1${cleaned}`
}

export const formatUSPhoneNumberForDisplay = (phone: string): string => {
  const cleaned = phone.replace(/^\+1/, '') // Remove the leading '+1'

  if (cleaned.length !== 10) {
    throw new Error('Invalid U.S. phone number format')
  }

  const areaCode = cleaned.slice(0, 3)
  const centralOfficeCode = cleaned.slice(3, 6)
  const lineNumber = cleaned.slice(6)

  return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`
}
