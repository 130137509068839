import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { SelectChangeEvent } from '@mui/material'
import {
  DispositionData,
  MULTIPACK_DISPOSITION_LIST,
  WASTED_REASON_LIST,
} from 'common/disposition'
import { AssetData, Scan } from 'common/types'
import { useEditDocumentedListContext } from 'lib/context/EditDocumentedListContext/EditDocumentedListContext'
import {
  EditDispositionModalLogicProps,
  EditDocumentedScanInput,
} from 'lib/context/EditDocumentedListContext/EditDocumentedList.types'
import { useImplantSites } from 'lib/context/ImplantSitesContext'
import { ValidationErrorMap } from 'components/BatchDispositionModal/BatchDispositionModal.types'
import { useDispositionValidationSchema } from './EditDispositionModal.validation'
import { getZodError } from 'common/utils'

export const useEditDispositionModalLogic = (
  props: EditDispositionModalLogicProps
) => {
  const {
    assetTray,
    isMultipack,
    isMultipackHardware,
    isManualAddition,
    singleConsumablesCount,
  } = props
  const {
    setScanStateMap,
    foundScanRef,
    isSavingEditedAsset,
    submitDisposition,
    isSelectModalOpen,
    setIsSelectModalOpen,
    setIsEditModalOpen,
    setScans,
    scans,
    setAssetGroupId,
    assetGroupId,
    isEditModalOpen,
    handleReset,
    scanStateMap,
    scanIsMatching,
    consumableAssetGroup,
    setConsumableAssetGroup,
    isLoading,
  } = useEditDocumentedListContext()
  const { dispositionSchema } = useDispositionValidationSchema(isManualAddition)

  const { implantSites } = useImplantSites()
  const implantSiteList = implantSites.map((implantSite) => implantSite.name)

  // handles multiple scans with the same disposition
  const scanToUseInForm = foundScanRef.current ? foundScanRef.current : scans[0]

  const selectIsSelectedId = () => {
    const selectedEntry = Object.entries(scanStateMap).find(
      ([, value]) => value.isSelected
    )

    return selectedEntry ? selectedEntry[0] : undefined
  }

  const selectedAssetId =
    scans.length > 1 ? selectIsSelectedId() : scans[0]?._id

  // Memoize initialFormState to prevent re-initialization on every render
  const initialFormState = useMemo(
    () => ({
      siteLocation: scanToUseInForm?.siteLocation,
      implantStatus: scanToUseInForm?.implantStatus,
      assetType:
        scanToUseInForm?.assetType || consumableAssetGroup?.scans[0].assetType,
      implantSite: scanToUseInForm?.implantSite || undefined,
      wastedReason:
        (scanToUseInForm?.wastedReason?.toUpperCase() as DispositionData['wastedReason']) ||
        undefined,
      assetTray: assetTray || '',
      explantedReason: scanToUseInForm?.explantedReason,
      explantedReasonNote: scanToUseInForm?.explantedReasonNote,
      count:
        singleConsumablesCount ||
        scanToUseInForm?.count ||
        consumableAssetGroup?.total,
    }),
    [scanToUseInForm, consumableAssetGroup, assetTray]
  )

  // States
  const [formState, setFormState] =
    useState<Partial<DispositionData>>(initialFormState)
  const [isMediaActionTriggered, setIsMediaActionTriggered] = useState(false)

  const [validationErrors, setValidationErrors] = useState<ValidationErrorMap>(
    {}
  )

  // Variables
  const isSiteLocationEnabled =
    (formState.implantStatus === 'IMPLANTED' ||
      formState.implantStatus === 'EXPLANTED') &&
    (formState.implantSite === 'Other' || formState.implantSite === 'Mouth')

  const isConsumable = formState.assetType === 'consumable'

  const isImplantedOrExplanted =
    formState.implantStatus === 'IMPLANTED' ||
    formState.implantStatus === 'EXPLANTED'

  const isNotWastedOrAssociated =
    formState.implantStatus !== 'WASTED' &&
    formState.implantStatus !== 'ASSOCIATED_ASSET'

  const renderImplantSites =
    !isConsumable &&
    isImplantedOrExplanted &&
    !!formState.implantStatus &&
    isNotWastedOrAssociated

  // Check if any of the form inputs have changed from the initial values
  const areInputsUnchanged = useMemo(() => {
    return (
      JSON.stringify(formState) === JSON.stringify(initialFormState) &&
      !isMediaActionTriggered
    )
  }, [formState, initialFormState, isMediaActionTriggered])

  const implantStatusLabel = MULTIPACK_DISPOSITION_LIST.find(
    (disposition) => disposition.id === formState?.implantStatus
  )?.name

  const wastedReasonLabel = WASTED_REASON_LIST.find(
    (reason) => reason.id === formState?.wastedReason
  )?.name

  const explantedReasonLabel = WASTED_REASON_LIST.find(
    (reason) => reason.id === formState?.explantedReason
  )?.name

  // Handlers
  const handleEditOnOpen = (
    agId: AssetData['_id'],
    scansToEdit?: Scan[],
    scanIds?: Scan['_id'],
    consumableAssetGroup?: AssetData
  ) => {
    setAssetGroupId(agId)

    const isMultipleAssets = scanIds?.includes(',') || false
    if (!isMultipleAssets && scansToEdit) {
      setScans(scansToEdit)
      setIsEditModalOpen(true)
    } else if (scansToEdit) {
      const scanIdsArray = scanIds?.split(',')
      const filteredScans: Scan[] = scansToEdit?.filter((scan) =>
        scanIdsArray?.includes(scan._id)
      )
      setScans(filteredScans)
      setIsSelectModalOpen(true)
    } else if (consumableAssetGroup) {
      setConsumableAssetGroup(consumableAssetGroup)
      setIsEditModalOpen(true)
    }
  }

  const toggleSelection = (scanId: string) => {
    setScanStateMap((s) => ({
      ...s,
      [scanId]: {
        ...s[scanId],
        isSelected: !s[scanId]?.isSelected,
      },
    }))
  }

  const handleChangeFormData = (
    e: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string> | any
  ) => {
    const { name, value } = e.target

    if (
      formState.implantSite === 'Mouth' &&
      name === 'siteLocation' &&
      value.length > 4
    ) {
      return
    }

    const resetValues: Partial<DispositionData> = {}

    if (name === 'assetType') {
      resetValues.assetType = value
      resetValues.implantStatus = undefined
      resetValues.implantSite = undefined
      resetValues.wastedReason = undefined
      resetValues.explantedReason = null
      resetValues.explantedReasonNote = null
      resetValues.siteLocation = undefined
    }

    if (name === 'implantStatus') {
      resetValues.implantStatus = value
      resetValues.implantSite = undefined
      resetValues.wastedReason = undefined
      resetValues.explantedReason = null
      resetValues.explantedReasonNote = null
      resetValues.siteLocation = undefined
    }

    if (name === 'implantSite') {
      resetValues.implantSite = value
      resetValues.siteLocation = undefined
      if (!isManualAddition) {
        resetValues.explantedReason = null
        resetValues.explantedReasonNote = null
      }
      resetValues.siteLocation = undefined
    }

    if (name === 'explantedReason') {
      resetValues.explantedReason = value
      resetValues.explantedReasonNote = null
    }

    if (name === 'count') {
      resetValues.count = value
    }

    setFormState((prev: Partial<DispositionData>) => ({
      ...prev,
      ...resetValues,
      [name]: value,
    }))
  }

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement> | undefined) => {
    if (e) {
      e.preventDefault()

      const testFormState = { ...formState, count: String(formState.count) }
      const result = dispositionSchema.safeParse(testFormState)

      if (!result.success) {
        setValidationErrors(getZodError(result.error))
        return
      }

      const deviceId = scanToUseInForm?.deviceId
      const scanId = scanToUseInForm?._id

      const scanObject = {
        deviceId: deviceId,
        assetGroupId: assetGroupId || consumableAssetGroup?._id,
        scanId: scanId,
        isMultipack: isMultipack || isMultipackHardware,
      }

      if (scanObject) {
        submitDisposition(
          result.data,
          scanObject as EditDocumentedScanInput
        ).then((result) => {
          if (result) {
            setIsEditModalOpen(false)
            setIsMediaActionTriggered(false)
          } else {
            console.error('Error submitting disposition')
          }
        })
      }
    }
  }

  // Lifecycle methods
  useEffect(() => {
    setValidationErrors({})
  }, [formState])

  return {
    handleEditOnOpen,
    toggleSelection,
    isSelectModalOpen,
    setIsEditModalOpen,
    setIsSelectModalOpen,
    formState,
    handleChangeFormData,
    handleSubmit,
    implantSiteList,
    isSavingEditedAsset,
    renderImplantSites,
    isConsumable,
    setScanStateMap,
    isEditModalOpen,
    handleReset,
    scanStateMap,
    scans,
    selectedAssetGroupId: assetGroupId || consumableAssetGroup?._id || '',
    scanIsMatching,
    isSiteLocationEnabled,
    selectedAssetId,
    consumableAssetGroup,
    isLoading,
    areInputsUnchanged,
    implantStatusLabel,
    wastedReasonLabel,
    explantedReasonLabel,
    isMediaActionTriggered,
    setIsMediaActionTriggered,
    validationErrors,
  }
}
