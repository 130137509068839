import { Button, CircularProgress, Typography } from '@mui/material'

import { useTrayDetails } from './TrayDetails.logic'

import './TrayDetails.scss'
import { Link } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'
import { LoadingButton } from 'components/mui'
import { DataTable } from 'components/molecules/DataTable/DataTable'
import ApproveRejectTray from 'components/molecules/ApproveRejectTray/ApproveRejectTray'
import dayjs from 'lib/dayjs'
import useTrayManagementLogic from '../TrayManagement/TrayManagement.logic'

const TrayDetails = () => {
  const {
    loading,
    error,
    trayItem,
    deleting,
    handleDeleteTray,
    subTraysTableBodyCells,
    subTraysTableHeaderCells,
    isApproveRejectTrayOpen,
    handleApproveRejectTrayClick,
    isApproving,
    isRejecting,
    handleUpdateTrayApprovalStatus,
    isSPD,
    trayDetails,
    handleViewTrayFiles,
    notAssignedButSelectedSurgeon,
  } = useTrayDetails()
  const { getTrayStatusDisplay } = useTrayManagementLogic()

  return (
    <div className="tray-details">
      <div data-testid="header" className="header contained-desktop">
        <div>
          <Button
            variant="text"
            className="back_button"
            to=".."
            component={Link}
          >
            <ArrowBack sx={{ mr: 0.5 }} /> BACK
          </Button>
        </div>

        <div className="header_actions">
          {isSPD && trayDetails?.trayCategory !== 'facility' && (
            <Button
              variant="outlined"
              sx={{ width: 'auto' }}
              onClick={handleApproveRejectTrayClick}
            >
              Approve / Reject Tray
            </Button>
          )}

          <LoadingButton
            className="delete"
            dataTestId="delete-tray-button"
            loading={deleting}
            disabled={deleting}
            sx={{ width: 'auto' }}
            mode="delete"
            onClick={handleDeleteTray}
          >
            Delete Tray
          </LoadingButton>
        </div>
      </div>
      <div className="contained-desktop">
        <Typography variant="h1">Tray Details</Typography>

        <ApproveRejectTray
          open={isApproveRejectTrayOpen}
          onClose={handleApproveRejectTrayClick}
          isApproving={isApproving}
          isRejecting={isRejecting}
          handleApproval={() => handleUpdateTrayApprovalStatus('approved')}
          handleRejection={(rejectionReason) =>
            handleUpdateTrayApprovalStatus('rejected', rejectionReason)
          }
        />

        <div className="content">
          {loading && !error && <CircularProgress />}

          {error && !loading && (
            <Typography className="error" variant="body1">
              {error.message}
            </Typography>
          )}

          {!error && !loading && trayItem && (
            <>
              <div className="tray-details_box">
                <div className="tray-details_details">
                  <Typography className="tray-details_detail">
                    Tray ID: <strong>{trayItem?.barcode}</strong>
                  </Typography>
                  <Typography className="tray-details_detail">
                    Description: <strong>{trayItem?.description}</strong>
                  </Typography>
                  {notAssignedButSelectedSurgeon && (
                    <Typography className="tray-details_detail">
                      Selected Surgeon:{' '}
                      <strong>{trayDetails?.selectedSurgeon}</strong>
                    </Typography>
                  )}
                  <Typography className="tray-details_detail">
                    Status:{' '}
                    <strong>{getTrayStatusDisplay(trayDetails?.status)}</strong>
                  </Typography>
                </div>

                <div className="tray-details_right">
                  <LoadingButton
                    variant="outlined"
                    size="small"
                    onClick={handleViewTrayFiles}
                  >
                    {trayDetails?.trayCategory !== 'facility' && 'IFU Files +'}{' '}
                    Inventory Sheets
                  </LoadingButton>
                  <div className="tray-details_updated">
                    <Typography variant="body2" className="tray-details_detail">
                      Last updated:{' '}
                      <strong>
                        {trayItem?.statusDetails?.lastUpdated
                          ? dayjs(trayItem?.statusDetails?.lastUpdated).format(
                              'MM/DD/YYYY hh:mm A'
                            )
                          : 'N/A'}
                      </strong>
                    </Typography>
                    <Typography variant="body2" className="tray-details_detail">
                      Last updated by:{' '}
                      <strong>
                        {trayItem?.statusDetails?.lastUpdatedBy
                          ? trayItem?.statusDetails?.lastUpdatedBy
                          : 'N/A'}
                      </strong>
                    </Typography>
                  </div>
                </div>
              </div>

              <div className="sub-trays-table_container">
                <DataTable
                  tableHeaders={subTraysTableHeaderCells}
                  tableRows={subTraysTableBodyCells(trayItem.subItems)}
                  isLoading={false}
                  isErrored={false}
                  disableSorting
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default TrayDetails
