import React, { createContext, useContext, useEffect, useState } from 'react'
import { AlertColor, AlertPropsColorOverrides } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'

import { TrayType as TrayCategory } from 'components/organisms/AddTraySelectType/AddTraySelectType.types'
import { InventorySheetFile } from './InventorySheets/InventorySheets.types'
import { BetterIDTrayScrew } from 'views/SPDLayout/SPD/SPD.types'
import { AddTrayContextProps } from './AddTray.types'
import { OverridableStringUnion } from '@mui/types'
import { Surgery } from 'common/types'
import { TrayType } from 'components/organisms/EditTrayModal/EditTrayModal.types'
import useSubmitTrayCapture from 'views/DigitalTrayMapping/CaptureTray/CaptureCamera/SubmitTrayCapture.logic'
import { getAnalysisJSONFileName } from 'lib/utils/getAnalysisJSONFile'
import { useLocation } from 'react-router-dom'
import { SubTray } from './SubTrays/SubTrays.types'

const AddTrayContext = createContext<AddTrayContextProps | undefined>(undefined)

export const AddTrayProvider: React.FC = ({ children }) => {
  const { pathname } = useLocation()
  const {
    analysisFileName,
    setAnalysisFileName,
    handleSubmit: handleSubmitAnalysis,
    analysisScrews,
    setIsAnalysisFromSPD,
    isComplete,
    setIsComplete,
    analysisError,
    setAnalysisError,
  } = useSubmitTrayCapture()

  const [traySurgeryId, setTraySurgeryId] = useState<Surgery['_id']>('')
  const [traySurgery, setTraySurgery] = useState<Surgery | undefined>(undefined)
  const [trayBarcode, setTrayBarcode] = useState<string>('')
  const [showScanner, setShowScanner] = useState<boolean>(false)
  const [trayDescription, setTrayDescription] = useState<string>('')
  const [selectedLocationId, setSelectedLocationId] = useState<
    number | undefined
  >(undefined)
  const [trayVendor, setTrayVendor] = useState<string>('')
  const [trayWeight, setTrayWeight] = useState<number | undefined>(undefined)
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false)
  const [snackbarState, setSnackbarState] = useState<
    OverridableStringUnion<AlertColor, AlertPropsColorOverrides> | undefined
  >('success')
  const [snackbarMessage, setSnackbarMessage] = useState<string>('')
  const [snackbarIcon, setSnackbarIcon] = useState<JSX.Element>(
    <CheckCircle color="success" />
  )
  const [trayAnalysisScrews, setTrayAnalysisScrews] = useState<
    BetterIDTrayScrew[]
  >([])
  const [trayImage, setTrayImage] = useState<InventorySheetFile | undefined>(
    undefined
  )
  const [selectedTrayCategory, setSelectedTrayCategory] = useState<
    TrayCategory['category'] | null
  >(null)
  const [selectedTrayType, setSelectedTrayType] = useState<TrayType>(undefined)
  const [isAnalysisComplete, setIsAnalysisComplete] = useState<boolean>(false)
  const [subTrays, setSubTrays] = useState<SubTray[]>([])
  const [selectedSurgeon, setSelectedSurgeon] = useState<string>('')
  const [trayAddConfirmation, setTrayAddConfirmation] = useState<
    boolean | undefined
  >()

  useEffect(() => {
    if (selectedTrayType) {
      const analysisFileName = getAnalysisJSONFileName(selectedTrayType)

      setAnalysisFileName(analysisFileName)
    }
  }, [selectedTrayType, pathname, setAnalysisFileName])

  /**
   * Handles closing the snackbar.
   * @param {React.SyntheticEvent | Event} event - The event object.
   * @param {string | undefined} reason - The reason for closing the snackbar.
   */
  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string | undefined
  ) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarOpen(false)
  }

  return (
    <AddTrayContext.Provider
      value={{
        traySurgeryId,
        setTraySurgeryId,
        traySurgery,
        setTraySurgery,
        trayBarcode,
        setTrayBarcode,
        showScanner,
        setShowScanner,
        trayDescription,
        setTrayDescription,
        selectedLocationId,
        setSelectedLocationId,
        trayVendor,
        setTrayVendor,
        trayWeight,
        setTrayWeight,
        snackbarOpen,
        setSnackbarOpen,
        snackbarState,
        setSnackbarState,
        snackbarMessage,
        setSnackbarMessage,
        snackbarIcon,
        setSnackbarIcon,
        handleSnackbarClose,
        trayAnalysisScrews,
        setTrayAnalysisScrews,
        trayImage,
        setTrayImage,
        selectedTrayCategory,
        setSelectedTrayCategory,
        selectedTrayType,
        setSelectedTrayType,
        isAnalysisComplete,
        setIsAnalysisComplete,
        analysisFileName,
        setAnalysisFileName,
        handleSubmitAnalysis,
        analysisScrews,
        setIsAnalysisFromSPD,
        isComplete,
        setIsComplete,
        analysisError,
        setAnalysisError,
        subTrays,
        setSubTrays,
        selectedSurgeon,
        setSelectedSurgeon,
        setTrayAddConfirmation,
        trayAddConfirmation,
      }}
    >
      {children}
    </AddTrayContext.Provider>
  )
}

/**
 * Custom hook to access the AddTrayContext.
 * @returns {AddTrayContextProps} The context value of the add tray context.
 */
export const useAddTrayContext = (): AddTrayContextProps => {
  const context = useContext(AddTrayContext)

  if (!context) {
    throw new Error('useAddTrayContext must be used with AddTrayProvider')
  }

  return context
}
