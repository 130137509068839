import { FC } from 'react'

// Components
import Header from 'components/molecules/Header/Header'
import { DataTable } from 'components/molecules/DataTable/DataTable'
import AddNewLaborCost from './AddNewLaborCost/AddNewLaborCost'
import { laborCostTableHeaders } from './tableData'
import AlertDialog from 'components/AlertDialog/AlertDialog'

// Logic
import useLaborCostLogic from './LaborCost.logic'

// Style
import './LaborCost.scss'
import Select from 'components/molecules/Select/Select'
import { Box } from '@mui/material'

const LaborCost: FC = () => {
  const {
    modal,
    modalId,
    isLoading,
    tableData,
    editedCost,
    deletedCost,
    isLoadingDelete,
    isErrorLaborCost,
    isLoadingLaborCost,
    selectedProcedure,
    procedureTypesOptions,
    isLoadingProcedureTypes,
    setModal,
    handleSave,
    handleDelete,
    setDeletedCost,
    setSelectedProcedure,
  } = useLaborCostLogic()

  return (
    <div className="labor-cost-main-container">
      <Header
        title="Labor Cost"
        parent="Setup"
        actions={[
          {
            title: 'Add',
            onClick: () => setModal({ isOpen: true, mode: 'create' }),
          },
        ]}
      />
      <div className="labor-cost-container">
        <div className="search">
          <Select
            isLoading={isLoadingProcedureTypes}
            label="Search procedures"
            fullWidth
            options={procedureTypesOptions ?? []}
            onSelect={(value) => setSelectedProcedure(value)}
            selectedValue={selectedProcedure?.name}
            onClear={() => setSelectedProcedure(undefined)}
          />
        </div>

        <Box sx={{ px: 2 }}>
          <DataTable
            data-testid="labor-cost-table"
            tableHeaders={laborCostTableHeaders}
            tableRows={tableData || []}
            isLoading={isLoadingLaborCost}
            isErrored={isErrorLaborCost}
            disableSorting
          />
        </Box>
      </div>

      <AddNewLaborCost
        key={modalId}
        mode={modal.mode}
        open={modal.isOpen}
        data={editedCost}
        isLoading={isLoading}
        onSave={handleSave}
        onClose={() => setModal({ isOpen: false })}
      />

      <AlertDialog
        isOpen={deletedCost.isOpen}
        description={`Are you sure you want to delete the labor cost of ${
          deletedCost?.procedureType || 'this procedure'
        }?`}
        title={`Delete the labor cost of ${deletedCost?.procedureType}`}
        zIndex={10000}
        primaryButtonText="Delete"
        primaryButtonAction={handleDelete}
        secondaryButtonAction={() => setDeletedCost({ isOpen: false })}
        secondaryButtonText="Cancel"
        isPrimaryLoading={isLoadingDelete}
      />
    </div>
  )
}

export default LaborCost
