import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TextField, Typography, Box } from '@mui/material'
import { LoadingButton } from 'components/mui'
import { useCreateGuestMutation } from '../../lib/apollo/hooks'

import './AddGuestInfo.scss'

const AddGuestInfo: React.FC = () => {
  const navigate = useNavigate()
  const { addGuest, loading: isSubmitting } = useCreateGuestMutation()
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    phone: '',
    email: '',
  })

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    try {
      const result = await addGuest(formData)
      if (result) {
        navigate('/tray-management/add-tray')
      } else {
        console.error('Failed to add guest user')
      }
    } catch (error) {
      console.error('Error submitting guest user:', error)
    }
  }

  return (
    <Box className="guest-info-container">
      <Box className="guest-info-form">
        <Typography variant="h4" className="form-title">
          Guest User Details
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            name="name"
            label="Name"
            fullWidth
            required
            value={formData.name}
            onChange={handleInputChange}
            margin="normal"
            className="form-input"
          />

          <TextField
            name="company"
            label="Company"
            fullWidth
            required
            value={formData.company}
            onChange={handleInputChange}
            margin="normal"
            className="form-input"
          />

          <TextField
            name="phone"
            label="Phone Number"
            fullWidth
            required
            value={formData.phone}
            onChange={handleInputChange}
            margin="normal"
            className="form-input"
          />

          <TextField
            name="email"
            label="Email"
            type="email"
            fullWidth
            required
            value={formData.email}
            onChange={handleInputChange}
            margin="normal"
            className="form-input"
          />

          <Box className="button-container">
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              disabled={
                isSubmitting || Object.values(formData).some((value) => !value)
              }
              className="submit-button"
            >
              Done
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </Box>
  )
}

export default AddGuestInfo
