import { ArrowBack } from '@mui/icons-material'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import AddTrayDetails from 'components/organisms/AddTrayDetails/AddTrayDetails'

import './MainTrayInfo.scss'
import TrayCaseDetails from 'components/organisms/TrayCaseDetails/TrayCaseDetails'

const MainTrayInfo = () => {
  return (
    <>
      <div className="main-tray-info-page_header">
        <Button variant="text" component={Link} to="/tray-management">
          <ArrowBack /> Back
        </Button>
      </div>
      <div className="main-tray-info-page_container">
        <div className="tray-details_container">
          <AddTrayDetails />
        </div>

        <div className="case-details_container">
          <TrayCaseDetails />
        </div>
      </div>
    </>
  )
}

export default MainTrayInfo
