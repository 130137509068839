import { CircularProgress, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { BackOrButton } from 'components/BackOrButton'

import { useSubTrayDetails } from './SubTrayDetails.logic'

import './SubTrayDetails.scss'
import { ArrowBack } from '@mui/icons-material'
import TrayProduct from 'views/DigitalTrayMapping/AssignedDigitalTrays/TrayProducts/TrayProduct/TrayProduct'
import { LoadingButton } from 'components/mui'

const SubTrayDetails = () => {
  const { trayId } = useParams<{ trayId: string }>()
  const {
    loading,
    error,
    subTrayItem,
    subTrayDetails,
    handleEditTrayClick,
    loadingParentTray,
    parentTrayError,
    shouldEditButtonBeVisible,
  } = useSubTrayDetails()

  return (
    <div className="sub-tray-details_wrapper">
      <div data-testid="header" className="header contained-desktop">
        <div className="buttons_container">
          <BackOrButton
            variant="outlined"
            size="small"
            className="back_button"
            sx={{ width: 'auto' }}
            fallbackUrl={`/tray-management/${trayId}`}
            fallbackChildren={
              <>
                <ArrowBack sx={{ mr: 0.5 }} /> Tray Home
              </>
            }
          >
            <ArrowBack sx={{ mr: 0.5 }} /> BACK
          </BackOrButton>

          <div className="right_buttons">
            {shouldEditButtonBeVisible && (
              <LoadingButton variant="outlined" onClick={handleEditTrayClick}>
                Edit Sub Tray
              </LoadingButton>
            )}
          </div>
        </div>
        <Typography variant="h1">Sub Tray Details</Typography>
      </div>

      <div className="content">
        {(loading || loadingParentTray) && !error && !parentTrayError && (
          <CircularProgress />
        )}

        {(error || parentTrayError) && !loading && !loadingParentTray && (
          <Typography className="error" variant="body1">
            {error.message || parentTrayError?.message}
          </Typography>
        )}

        {!error &&
          !parentTrayError &&
          !loading &&
          !loadingParentTray &&
          subTrayItem && (
            <>
              <div className="img_container">
                <img
                  src={subTrayDetails?.trayImg}
                  alt={subTrayItem.description}
                />
              </div>

              {subTrayDetails?.screws.length === 0 ? (
                <Typography variant="body2">No screws in this tray</Typography>
              ) : (
                <Typography variant="h2">
                  {subTrayDetails?.screws?.length} Screws
                </Typography>
              )}

              <div className="sub-tray-details_items">
                {subTrayDetails?.screws.map((screw, _index) => (
                  <TrayProduct
                    key={`${screw.deviceId}${_index}`}
                    {...screw}
                    showDispositionStatus={false}
                  />
                ))}
              </div>
            </>
          )}
      </div>
    </div>
  )
}

export default SubTrayDetails
