// MUI
import { Box, IconButton } from '@mui/material'
import { NavigateBefore, NavigateNext } from '@mui/icons-material'

// Components
import Header from 'components/molecules/Header/Header'
import CustomDatePicker from 'components/molecules/DatePicker/DatePicker'
import { TabPanel } from 'components/molecules/TablPanel/TabPanel'
import { DataTable } from 'components/molecules/DataTable/DataTable'
import Select from 'components/molecules/Select/Select'

// Style
import './PreferenceCards.scss'

// Logic
import useLogic from './PreferenceCards.logic'
import AlertDialog from 'components/AlertDialog/AlertDialog'

const PreferenceCards = () => {
  const {
    isError,
    afterDate,
    searchSurgeon,
    proceduresData,
    isErrorPrefCard,
    searchProcedure,
    preferenceCards,
    prefCardHeadCells,
    isLoadingPrefCards,
    isLoadingProcedures,
    proceduresHeadCells,
    surgeonsOptions,
    isLoadingSurgeons,
    procedureTypesOptions,
    isLoadingProcedureTypes,
    isDeleting,
    isAlertOpen,
    handleDelete,
    setIsAlertOpen,
    handleBackDay,
    setSearchSurgeon,
    handleForwardDay,
    setSearchProcedure,
    handleCreatePrefCard,
  } = useLogic()

  return (
    <div className="pref-cards-main-container">
      <Header
        parent="Reports"
        title="Procedures"
        actions={[
          {
            onClick: () => handleCreatePrefCard(false),
            title: 'Create',
          },
        ]}
      />

      <TabPanel
        customTabPanelSx={{
          p: 0,
          mt: 0,
        }}
        tabsConfig={[
          {
            label: 'Scheduled Procedures',
            content: (
              <div className="surgeries">
                <Box
                  aria-label="date-picker-box"
                  alignItems="center"
                  flexDirection="row"
                  display="flex"
                >
                  <IconButton
                    onClick={handleBackDay}
                    size="small"
                    data-testid="procedures-back-button"
                  >
                    <NavigateBefore fontSize="large" />
                  </IconButton>

                  <CustomDatePicker
                    label="Procedure List Date"
                    date={afterDate}
                    renderDatePicker={false}
                  />

                  <IconButton
                    onClick={handleForwardDay}
                    size="small"
                    data-testid="procedures-forward-button"
                  >
                    <NavigateNext fontSize="large" />
                  </IconButton>
                </Box>
                <Box sx={{ px: 2 }}>
                  <DataTable
                    data-testid="procedures-table"
                    tableHeaders={proceduresHeadCells}
                    tableRows={proceduresData || []}
                    isLoading={isLoadingProcedures}
                    isErrored={isError}
                  />
                </Box>
              </div>
            ),
          },
          {
            label: 'Preference Cards',
            content: (
              <div className="pref-cards">
                <div className="filters">
                  <div className="search">
                    <div className="input">
                      <Select
                        isLoading={isLoadingSurgeons}
                        label="Search surgeon"
                        options={surgeonsOptions ?? []}
                        onSelect={(value) => setSearchSurgeon(value.name)}
                        selectedValue={(searchSurgeon as string) ?? ''}
                        fullWidth
                        onClear={() => setSearchSurgeon(undefined)}
                      />
                    </div>
                    <div className="input">
                      <Select
                        isLoading={isLoadingProcedureTypes}
                        label="Search procedure"
                        fullWidth
                        options={procedureTypesOptions ?? []}
                        onSelect={(value) => setSearchProcedure(value.name)}
                        selectedValue={searchProcedure}
                        onClear={() => setSearchProcedure(undefined)}
                      />
                    </div>
                  </div>
                </div>
                <Box sx={{ px: 2 }}>
                  <DataTable
                    data-testid="preference-cards-table"
                    tableHeaders={prefCardHeadCells}
                    tableRows={preferenceCards || []}
                    isLoading={isLoadingPrefCards}
                    isErrored={isErrorPrefCard}
                  />
                </Box>
              </div>
            ),
          },
        ]}
      />

      <AlertDialog
        key={isAlertOpen.id}
        zIndex={1000}
        mode="warning"
        title="Delete Confirmation"
        description={`Are you sure you want to delete this preference card?`}
        isOpen={isAlertOpen.isOpen}
        isPrimaryLoading={isDeleting}
        primaryButtonText="Delete"
        primaryButtonAction={() => handleDelete()}
        secondaryButtonAction={() => setIsAlertOpen({ isOpen: false })}
      />
    </div>
  )
}

export default PreferenceCards
