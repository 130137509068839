import { Button, ButtonProps } from '@mui/material'
import { useNavigate, useNavigationType } from 'react-router-dom'

interface Props {
  fallbackUrl: string
  fallbackChildren: React.ReactNode
}

export function BackOrButton({
  children,
  fallbackUrl,
  fallbackChildren,
  ...props
}: ButtonProps & Props) {
  const navigate = useNavigate()
  const navType = useNavigationType()
  const canGoBack = navType === 'PUSH'

  return (
    <Button
      onClick={() => (canGoBack ? navigate(-1) : navigate(fallbackUrl))}
      {...props}
    >
      {canGoBack ? children : fallbackChildren}
    </Button>
  )
}
