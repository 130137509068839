import { z } from 'zod'

export type TNewReferringPhysicianState = {
  _id: string
  edit: boolean
  firstName: string
  lastName: string
  email: string
  speciality?: string
  mobile?: string
}

export const physicianDetailsFormSchema = z
  .object({
    firstName: z
      .string()
      .min(1, { message: 'Physician first name cannot be empty' })
      .regex(/^\S*$/, { message: 'First name cannot contain spaces' }),
    lastName: z
      .string()
      .min(1, { message: 'Physician last name cannot be empty' })
      .regex(/^\S*$/, { message: 'Last name cannot contain spaces' }),
    email: z.string().email({ message: 'Invalid email address' }),
    confirmEmail: z.string().email({ message: 'Invalid email address' }),
    mobile: z
      .string()
      .optional()
      .nullable()
      .refine(
        (value) => !value || /^\([2-9]\d{2}\) [2-9]\d{2}-\d{4}$/.test(value),
        {
          message:
            'Mobile number must be in the format (321) 323-2131 and use a valid U.S. area code',
        }
      ),
  })
  .refine((data) => data.email === data.confirmEmail, {
    path: ['confirmEmail'],
    message: 'Emails must match',
  })
