import { useFlags } from 'launchdarkly-react-client-sdk'
import { createContext, useContext, useMemo } from 'react'

export const ORGANIZATIONS = {
  pcoms: 'org_G2pgB4ADsuWZ2aNK',
  medgeo: 'org_OM4vswifgUpIYcu1',
  adventist: 'org_GR1VqYoVzjaaiJns',
}

type TOrganizationContext = {
  physiciansTitle: string
  showPhysiciansMobile: boolean
  showPhysiciansSpeciality: boolean
}

const OrganizationSettingsContext = createContext<
  TOrganizationContext | undefined
>(undefined)

const OrganizationSettingsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const physiciansTitle = useFlags().physiciansTitle || 'Referring Physicians'
  const showPhysiciansSpeciality = useFlags().showPhysiciansSpeciality || false
  const showPhysiciansMobile = useFlags().showPhysiciansMobile || false

  const value = useMemo(
    () => ({ showPhysiciansSpeciality, physiciansTitle, showPhysiciansMobile }),
    [physiciansTitle, showPhysiciansSpeciality, showPhysiciansMobile]
  )

  return (
    <OrganizationSettingsContext.Provider value={value}>
      {children}
    </OrganizationSettingsContext.Provider>
  )
}

const useOrganizationSettings = () => {
  const context = useContext(OrganizationSettingsContext)

  if (context === undefined)
    throw new Error(
      'useOrganizationSettings must be used within OrganizationSettingsProvider'
    )

  return context
}

export { OrganizationSettingsProvider, useOrganizationSettings }
