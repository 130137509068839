import { Inventory2, Medication, Settings } from '@mui/icons-material'
import { Route, Routes } from 'react-router-dom'
import { Alert, Snackbar } from '@mui/material'
import { Helmet } from 'react-helmet'

import { ConfirmLoadMultipleScrewsModal } from 'components/organisms/ConfirmLoadMultipleScrewsModal/ConfirmLoadMultipleScrewsModal'
import { ChooseTrayLocationModal } from 'components/organisms/ChooseTrayLocationModal/ChooseTrayLocationModal'
import SelectedTrayModal from '../../../components/organisms/SelectedTrayModal/SelectedTrayModal'
import EditTrayModal from 'components/organisms/EditTrayModal/EditTrayModal'
import SelectTray from './SelectTray/SelectTray'
import { LoadingButton } from 'components/mui'
import TrayMap from './TrayMap/TrayMap'
import Scan from './Scan/Scan'

import InventoryProvider from 'lib/apollo/inventory-config'
import { useSPDHook } from './SPD.logic'
import { useSPD } from './SPD.context'

import { ItemResponse } from 'common/types'
import './SPD.scss'
import { useSPDScan } from './Scan/Scan.context'

const SPD = () => {
  const {
    navbarHeight,
    trayData,
    showTrayDetails,
    setShowTrayDetails,
    showSurgeryChangeModal,
    setShowSurgeryChangeModal,
    showChooseTrayLocationModal,
    setShowChooseTrayLocationModal,
    snackbarOpen,
    snackbarState,
    snackbarMessage,
    snackbarIcon,
    handleSnackbarClose,
    trayDetails,
  } = useSPD()
  const { handleLocationClick, handleSurgeryClick, handleClick } = useSPDHook()
  const { isAdditionalDeviceCountModalOpen, isLoadMultipleBoxVisible } =
    useSPDScan()

  return (
    <div
      style={{ minHeight: `calc(100vh - ${navbarHeight}px)` }}
      className="spd-container_wrapper"
    >
      <Helmet>
        <title>SPD</title>
      </Helmet>

      <Routes>
        <Route path="/" element={<SelectTray />} />
        <Route path="/capture" element={<Scan />} />
        <Route
          path="tray-visualization"
          element={
            <div className="spd-tray-map-page_wrapper">
              <TrayMap trayType={trayDetails?.trayType} />
            </div>
          }
        />
      </Routes>

      {!isLoadMultipleBoxVisible && (
        <div className="config-buttons_container">
          <LoadingButton
            disabled={false}
            loading={false}
            variant="contained"
            onClick={handleLocationClick}
            customIcon={<Inventory2 />}
          >
            Storage Location
          </LoadingButton>

          <LoadingButton
            disabled={false}
            loading={false}
            variant="contained"
            onClick={handleSurgeryClick}
            customIcon={<Medication />}
          >
            Assign Surgery
          </LoadingButton>

          <LoadingButton
            className="tray-details-button"
            disabled={false}
            loading={false}
            variant="contained"
            onClick={handleClick}
          >
            <Settings className="settings-icon" />
          </LoadingButton>
        </div>
      )}

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarState}
          sx={{ width: '100%' }}
          icon={snackbarIcon}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <ConfirmLoadMultipleScrewsModal
        open={isAdditionalDeviceCountModalOpen}
        onClose={() => {
          return
        }}
      />

      <InventoryProvider>
        <EditTrayModal
          open={showSurgeryChangeModal}
          onClose={() => setShowSurgeryChangeModal(false)}
          trayData={trayData?.trayItem as ItemResponse}
        />

        <ChooseTrayLocationModal
          open={showChooseTrayLocationModal}
          onClose={() => setShowChooseTrayLocationModal(false)}
        />

        <SelectedTrayModal
          open={showTrayDetails}
          onClose={() => setShowTrayDetails(false)}
        />
      </InventoryProvider>
    </div>
  )
}

export default SPD
