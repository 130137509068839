import Header from 'components/molecules/Header/Header'
import AlertDialog from 'components/AlertDialog/AlertDialog'
import useReferringPhysiciansLogic from './ReferringPhysicians.logic'
import { Box, Button, Checkbox, TableRowProps, TextField } from '@mui/material'
import { DataTable } from 'components/molecules/DataTable/DataTable'
import { LoadingButton } from 'components/mui'
import { Delete, ReadMore, Email } from '@mui/icons-material'
import { useIsMobile } from 'lib/utils/mediaQueries'
import './ReferringPhysicians.scss'
import { formatUSPhoneNumberForDisplay } from 'common/utils'

const ReferringPhysicians = () => {
  const {
    selectAll,
    physicians,
    searchLabel,
    isAlertOpen,
    searchString,
    physiciansTitle,
    isSendingBulkEmail,
    filteredPhysicians,
    isLoadingPhysicians,
    showPhysiciansMobile,
    showPhysiciansSpeciality,
    errorGettingPhysiciansData,
    referringPhysiciansHeadCells,
    isDeletingReferringPhysician,
    sendEmail,
    handleFocus,
    handleSearch,
    sendBulkEmail,
    openAlertDialog,
    closeAlertDialog,
    handleBulkSelection,
    setIsSendingBulkEmail,
    handleCheckPhysicianClick,
    navigateToReferringPhysician,
    handleCreateReferringPhysician,
    handleDeleteReferringPhysician,
  } = useReferringPhysiciansLogic()

  const physiciansRows = filteredPhysicians
    ?.map((physician) => {
      if (!physician._id) return null

      const formattedLastName = physician.lastName
        ? physician.lastName
            .split(' ')
            .map(
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(' ')
        : '-'

      const formattedFirstName = physician.firstName
        ? physician.firstName
            .split(' ')
            .map(
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(' ')
        : '-'

      const row: {
        lastName: string
        firstName: string
        speciality?: String
        email: string
        mobile?: string
        actions?: JSX.Element
        sendEmail?: JSX.Element
      } = {
        lastName: formattedLastName,
        firstName: formattedFirstName,
        ...(showPhysiciansSpeciality && {
          speciality: physician.speciality || '-',
        }),
        email: physician.email,
        ...(showPhysiciansMobile && {
          mobile: physician.mobile
            ? formatUSPhoneNumberForDisplay(physician.mobile)
            : '-',
        }),
      }

      isSendingBulkEmail
        ? (row.sendEmail = (
            <div className="actions-referring-physicians checkbox">
              <Checkbox
                id={physician._id}
                checked={
                  filteredPhysicians.find((item) => item._id === physician._id)
                    ?.checked
                }
                onChange={handleCheckPhysicianClick}
              />
            </div>
          ))
        : (row.actions = (
            <div className="actions-referring-physicians">
              <Button onMouseUp={sendEmail(physician._id)}>
                <Email />
              </Button>
              <Button
                onMouseUp={navigateToReferringPhysician({
                  edit: true,
                  _id: physician._id,
                  email: physician.email,
                  lastName: physician.lastName,
                  firstName: physician.firstName,
                  speciality: physician.speciality,
                  mobile: physician.mobile
                    ? formatUSPhoneNumberForDisplay(physician.mobile)
                    : undefined,
                })}
              >
                <ReadMore />
              </Button>
              <Button onMouseUp={openAlertDialog(physician._id)}>
                <Delete color="error" />
              </Button>
            </div>
          ))

      return row
    })
    .filter(Boolean) as TableRowProps[]

  isSendingBulkEmail
    ? referringPhysiciansHeadCells.push({
        id: 'sendEmail',
        label: (
          <div className="checkbox-header-referring-physicians">
            <Checkbox
              id="selectAll"
              onChange={handleCheckPhysicianClick}
              checked={selectAll}
            />
          </div>
        ),
        sortable: false,
        numeric: false,
        sx: {
          textAlign: 'right',
        },
      })
    : referringPhysiciansHeadCells.push({
        id: 'actions',
        label: 'Actions',
        numeric: false,
        sx: {
          textAlign: 'right',
        },
      })

  return (
    <div className="referring-physicians-main-container">
      <Header
        isMobile={useIsMobile()}
        parent="Reports"
        title={physiciansTitle}
        hasBack={isSendingBulkEmail}
        onBackClick={() => setIsSendingBulkEmail(false)}
      />

      <div className="content">
        {physicians.length > 0 || isLoadingPhysicians ? (
          <>
            <div
              className={`input-container ${isSendingBulkEmail && 'flex-end'}`}
            >
              <div className={`search-add ${isSendingBulkEmail && 'hide'}`}>
                <TextField
                  className="input"
                  label={searchLabel}
                  value={searchString}
                  onChange={handleSearch}
                  onFocus={handleFocus(true)}
                  onBlur={handleFocus(false)}
                />
                <LoadingButton
                  className="add-physician-button"
                  variant="outlined"
                  size="large"
                  onClick={handleCreateReferringPhysician}
                >
                  Add Physician
                </LoadingButton>
              </div>
              <LoadingButton
                className="send-email-button"
                variant="outlined"
                size="large"
                onClick={
                  isSendingBulkEmail ? sendBulkEmail : handleBulkSelection
                }
                endIcon={<Email />}
              >
                Send All
              </LoadingButton>
            </div>

            <Box sx={{ px: 2 }}>
              <DataTable
                tableRows={physiciansRows}
                isLoading={isLoadingPhysicians}
                isErrored={!!errorGettingPhysiciansData}
                tableHeaders={referringPhysiciansHeadCells}
                errorMessage={`Error getting ${physiciansTitle.toLocaleLowerCase()}...`}
              />
            </Box>
          </>
        ) : (
          <div className="no-data">
            <h3>{`No ${physiciansTitle.toLocaleLowerCase()} found`}</h3>
            <div>
              <LoadingButton
                variant="contained"
                size="large"
                onClick={handleCreateReferringPhysician}
              >
                Add Physician
              </LoadingButton>
            </div>
          </div>
        )}
      </div>
      <AlertDialog
        zIndex={1000}
        mode="warning"
        title="Delete Confirmation"
        primaryButtonText="Delete"
        isOpen={isAlertOpen.isOpen}
        description={isAlertOpen.description}
        secondaryButtonAction={closeAlertDialog}
        isPrimaryLoading={isDeletingReferringPhysician}
        primaryButtonAction={handleDeleteReferringPhysician}
      />
    </div>
  )
}

export default ReferringPhysicians
