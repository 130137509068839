import { Button, Typography } from '@mui/material'
import { Add, ArrowBack } from '@mui/icons-material'
import { Link } from 'react-router-dom'

import './SubTrays.scss'
import { LoadingButton } from 'components/mui'
import useSubTraysLogic from './SubTrays.logic'
import { DataTable } from 'components/molecules/DataTable/DataTable'
import { useAddTrayContext } from '../AddTray.context'

const SubTrays = () => {
  const { subTrays } = useAddTrayContext()
  const {
    handleAddSubTrayClick,
    subTraysTableHeaderCells,
    subTraysTableBodyCells,
  } = useSubTraysLogic()

  return (
    <div className="sub-trays-page_container">
      <div className="header_wrapper">
        <div className="header_container">
          <Button className="back-button" component={Link} to="..">
            <ArrowBack sx={{ mr: 0.5 }} /> BACK
          </Button>
        </div>

        <div className="row">
          <Typography variant="h1">Trays</Typography>

          <LoadingButton onClick={handleAddSubTrayClick} startIcon={<Add />}>
            Add Sub Tray
          </LoadingButton>
        </div>
      </div>

      <div className="sub-trays-table_container">
        <DataTable
          tableHeaders={subTraysTableHeaderCells}
          tableRows={subTraysTableBodyCells(subTrays)}
          isLoading={false}
          isErrored={false}
          disableSorting
        />
        <div className="sub-trays-actions">
          {subTrays.length > 0 && (
            <Button
              variant="outlined"
              color="primary"
              sx={{ mt: 2 }}
              component={Link}
              to=".."
            >
              Complete
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default SubTrays
