import { useNavigate } from 'react-router-dom'
import SelectTrayIDModal from '../../../../components/organisms/SelectTrayIDModal/SelectTrayIDModal'
import { useSPD } from '../SPD.context'

import './SelectTray.scss'
import InventoryProvider from 'lib/apollo/inventory-config'

const SelectTray = () => {
  const navigate = useNavigate()
  const { trayData } = useSPD()

  return (
    <div className="select-tray-container">
      <InventoryProvider>
        <SelectTrayIDModal
          open={!trayData}
          onClose={() => navigate('/')}
          shouldBeFacilityOwned
        />
      </InventoryProvider>
    </div>
  )
}

export default SelectTray
